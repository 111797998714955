import Vue from "vue";
import Vuex from "vuex";
import Setting from "./Setting/index";

// AUTENTICATION + PROFILE
import auth from "./Auth";

// TOOLS
import tools from "./Tools";

// USERS
import users from "./User";

// SUPER ADMIN
import colleges from "./SuperAdmin/Colleges";
import education from "./SuperAdmin/Education";
import grades from "./SuperAdmin/Grades";
import modules from "./SuperAdmin/Modules";
import periods from "./SuperAdmin/Periods";
import companies from "./Companies";
import corporations from "./Corporations";
import contracts from "./Advertising/adContract";
import adServices from "./Advertising/adService";
import advertisings from "./Advertising/advertising";

// ADMINSTRATIVE
import courses from "./Administration/Courses";
import files from "./Administration/Files";
import UserCollege from "./Administration/UserCollege";
import collegeGoogleCalendars from "./Administration/CollegeGoogleCalendars";
import collegeApiCredentials from "./Administration/CollegeApiCredentials";

// NOTIFICATIONS
import notifications from "./Notifications";

// EVENTS
import events from "./Events";

// EVENTS
import categories from "./SuperAdmin/Categories";

// BLOG
import blog from "./Blog";

// Flyers
import flyers from "./Flyers";

// Messenger
import messenger from "./Messenger";

// Nursing
import nursing from "./Nursing";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    auth,
    colleges,
    Setting,
    tools,
    users,
    education,
    grades,
    courses,
    companies,
    corporations,
    contracts,
    adServices,
    advertisings,
    collegeGoogleCalendars,
    collegeApiCredentials,
    files,
    periods,
    UserCollege,
    flyers,
    messenger,
    nursing,
    notifications,
    events,
    categories,
    blog,
    modules,
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  strict: debug,
});
