import { ServiceApi } from "@/services/auth";

const state = {
  advertisings: [],
  advertising: null,
  loading: false,
  error: null,
};

const actions = {
  async fetchAdvertisings({ commit }, payload) {
    try {
      commit("advertisingsBegin");
      let response = await ServiceApi.get("/api/v1/advertisings", payload);
      commit("SET_ADVERTISINGS", response.data);
      commit("advertisingsSuccess", true);
      return response;
    } catch (err) {
      commit("advertisingsErr", err);
    }
  },
  async fetchAdvertising({ commit }, payload) {
    try {
      commit("advertisingsBegin");
      let response = await ServiceApi.get(`/api/v1/advertisings/${payload}`);
      commit("SET_ADVERTISING", response.data);
      commit("advertisingsSuccess", true);
      return response;
    } catch (err) {
      commit("advertisingsErr", err);
    }
  },
  async createOrUpdateAdvertising({ commit, dispatch }, payload) {
    let formData = new FormData();
    formData.append("name", payload.name);
    formData.append("ad_contract_id", payload.ad_contract_id);
    formData.append("url", payload.url);
    if (payload.image) formData.append("image", payload.image);
    try {
      commit("advertisingsBegin");
      if (!payload.id) {
        const resp = await ServiceApi.post(`/api/v1/advertisings`, formData);
        commit("advertisingsSuccess", true);
        commit("SET_NEW_ADVERTISING", resp.data);
        return resp;
      } else if (payload.id) {
        formData.append("_method", "PUT");
        const resp = await ServiceApi.post(
          `/api/v1/advertisings/${payload.id}`,
          formData
        );
        commit("advertisingsSuccess", true);
        return resp;
      }
    } catch (err) {
      commit("advertisingsErr", err);
    }
  },
  async deleteAdvertising({ commit }, payload) {
    try {
      commit("advertisingsBegin");
      const resp = await ServiceApi.delete(`/api/v1/advertisings/${payload}`);
      commit("SPLICE_ADVERTISING_DELETED", payload);
      commit("advertisingsSuccess", true);
      return resp;
    } catch (err) {
      commit("advertisingsErr", err);
    }
  },
};

const getters = {
  advertisings: (state) => state.advertisings,
  advertising: (state) => state.advertising,
  advertisingsLoading: (state) => state.loading,
};

const mutations = {
  SET_ADVERTISINGS(state, payload) {
    state.advertisings = payload;
  },
  SET_ADVERTISING(state, payload) {
    state.advertising = payload;
  },
  SET_NEW_ADVERTISING(state, payload) {
    state.advertisings.data.unshift(payload);
  },
  SPLICE_ADVERTISING_DELETED(state, payload) {
    state.advertisings.data = state.advertisings.data.filter(
      (item) => item.id !== payload
    );
  },
  advertisingsBegin(state) {
    state.loading = true;
  },
  advertisingsSuccess(state) {
    state.loading = false;
  },
  advertisingsErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
