import { ServiceApi } from "@/services/auth";

const state = {
  collegeApiCredentials: [],
  loading: false,
  error: null,
};

const actions = {
  async fetchCollegeApiCredentials({ commit, getters }, payload) {
    try {
      commit("apiCredentialsBegin");
      let response = await ServiceApi.get(
        `/api/v1/colleges/api-credentials/${getters.collageAuthUser.id}/all`,
        payload
      );
      commit("SET_COLLEGE_API_CREDENTIALS", response.data);
      commit("apiCredentialsSuccess");
      return response;
    } catch (err) {
      commit("apiCredentialsErr", err);
    }
  },
  async updateOrCreateCollegeApiCredentials(
    { commit, getters, dispatch },
    payload
  ) {
    try {
      commit("apiCredentialsBegin");
      if (!payload.id) {
        const resp = await ServiceApi.post(
          `/api/v1/colleges/api-credentials/${getters.collageAuthUser.id}/create`,
          payload
        );
        commit("SET_NEW_COLLEGE_API_CREDENTIALS", resp.data);
        commit("apiCredentialsSuccess");
        return resp;
      } else if (payload.id) {
        const resp = await ServiceApi.put(
          `/api/v1/colleges/api-credentials/${payload.id}/update`,
          payload
        );
        await dispatch("fetchCollegeApiCredentials");
        commit("apiCredentialsSuccess");
        return resp;
      }
    } catch (err) {
      commit("apiCredentialsErr", err);
    }
  },
  async deleteCollegeApiCredentials({ commit }, payload) {
    try {
      commit("apiCredentialsBegin");
      const resp = await ServiceApi.delete(
        `/api/v1/colleges/api-credentials/${payload}/delete`
      );
      commit("SET_DELETE_COLLEGE_API_CREDENTIALS", payload);
      commit("apiCredentialsSuccess");
      return resp;
    } catch (err) {
      commit("apiCredentialsErr", err);
    }
  },
};

const getters = {
  collegeApiCredentials: (state) => state.collegeApiCredentials,
  apiCredentialsLogin: (state) => state.loading,
};

const mutations = {
  SET_COLLEGE_API_CREDENTIALS(state, payload) {
    state.collegeApiCredentials = payload;
  },
  SET_NEW_COLLEGE_API_CREDENTIALS(state, payload) {
    state.collegeApiCredentials.unshift(payload);
  },
  SET_DELETE_COLLEGE_API_CREDENTIALS(state, payload) {
    state.collegeApiCredentials = state.collegeApiCredentials.filter(
      (item) => item.id !== payload
    );
  },
  apiCredentialsBegin(state) {
    state.loading = true;
  },
  apiCredentialsSuccess(state) {
    state.loading = false;
  },
  apiCredentialsErr(state, err) {
    state.loading = false;
    state.loadingSync = false;
    state.error = err;
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
