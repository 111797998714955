import { ServiceApi } from "@/services/auth";
import moment from "moment";

function color() {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}

const state = {
  eventsState: [],
  eventsCalendar: [],
  loading: false,
  error: null,
};

const actions = {
  async fetchEvents({ commit }, payload) {
    try {
      commit("eventsBegin");
      let response = await ServiceApi.get("/api/v1/events", payload);
      // commit('SET_EVENTS', response.data)
      commit("eventsSuccess", true);
      return response;
    } catch (err) {
      commit("eventsErr", err);
    }
  },
  async fetchEventsColleges({ commit, getters }, payload) {
    try {
      commit("eventsBegin");
      let response = await ServiceApi.get(
        `/api/v1/events/college/${getters.collageAuthUser.id}/all`,
        payload
      );
      commit("SET_EVENTS", payload.paginate ? response.data : response);
      commit("eventsSuccess", true);
      return response;
    } catch (err) {
      commit("eventsErr", err);
    }
  },
  async storeEvents({ commit, dispatch, getters }, payload) {
    let formData = new FormData();
    formData.append("name", payload.name);
    if (payload.description)
      formData.append("description", payload.description);
    formData.append("location", payload.location);
    formData.append("initial_date", payload.initial_date);
    formData.append("initial_time", payload.initial_time);
    formData.append("final_date", payload.final_date);
    formData.append("final_time", payload.final_time);
    if (payload.image) formData.append("image", payload.image);
    try {
      commit("eventsBegin");
      if (!payload.id) {
        const resp = await ServiceApi.post(
          `/api/v1/events/college/${getters.collageAuthUser.id}/create`,
          formData
        );
        commit("eventsSuccess", true);
        return resp;
      } else if (payload.id) {
        formData.append("_method", "PUT");
        const resp = await ServiceApi.post(
          `/api/v1/events/${payload.id}`,
          formData
        );
        commit("eventsSuccess", true);
        return resp;
      }
    } catch (err) {
      commit("eventsErr", err);
    }
  },
  async syncAllCollegeGoogleCalendarEvents({ commit, getters }, payload) {
    try {
      commit("eventsBegin");
      const resp = await ServiceApi.put(
        `/api/v1/events/college/${getters.collageAuthUser.id}/sync/all/calendars`
      );
      commit("eventsSuccess", true);
      return resp;
    } catch (err) {
      commit("eventsErr", err);
    }
  },
  async deleteEvents({ commit }, payload) {
    try {
      commit("eventsBegin");
      const resp = await ServiceApi.delete(`/api/v1/events/${payload}`);
      commit("SET_DELETE_EVENTS", payload);
      commit("eventsSuccess", true);
      return resp;
    } catch (err) {
      commit("eventsErr", err);
    }
  },
};

const getters = {
  getEvents: (state) => state.eventsState,
  getEventsCalendar: (state) => state.eventsCalendar,
  eventsLoading: (state) => state.loading,
};

const mutations = {
  SET_EVENTS(state, payload) {
    state.eventsState = payload;
    state.eventsCalendar = payload.data.map((item) => ({
      id: item.id,
      title: item.name,
      start: moment(item.initial_date).format("YYYY-MM-DD HH:mm"),
      end: moment(item.final_date).format("YYYY-MM-DD HH:mm"),
      backgroundColor: color(),
      borderColor: "#fff",
      textColor: "#eceff1",
      eventData: {
        id: item.id,
        user: item.owner.name,
        rut: item.owner.rut,
        title: item.name,
        description: item.description,
        start: moment(item.initial_date).format("DD-MM-YYYY HH:mm") + " Hrs",
        end: moment(item.final_date).format("DD-MM-YYYY HH:mm") + " Hrs",
      },
    }));
  },
  SET_NEW_EVENTS(state, payload) {
    state.eventsState.data.unshift(payload);
  },
  SET_DELETE_EVENTS(state, payload) {
    state.eventsState.data = state.eventsState.data.filter(
      (item) => item.id !== payload
    );
  },
  eventsBegin(state) {
    state.loading = true;
  },
  eventsSuccess(state) {
    state.loading = false;
  },
  eventsErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
