import "@babel/polyfill";
import Vue from "vue";
import "mutationobserver-shim";
import "./Utils/fliter";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Raphael from "raphael/raphael";
import "./plugins";
import "./registerServiceWorker";
import i18n from "./i18n";
import "./directives";
import TheMask from "vue-the-mask";
import vueRut from "vue-rut";

Vue.use(TheMask);
Vue.use(vueRut);

global.Raphael = Raphael;

Vue.config.productionTip = false;

const vm = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

window.vm = vm;
