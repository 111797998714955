import Vue from 'vue'
import * as rules from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import es from 'vee-validate/dist/locale/es.json'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
localize('es', es)
