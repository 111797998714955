import { ServiceApi } from "@/services/auth";

const state = {
  filesState: [],
  loading: false,
  error: null,
};

const actions = {
  async fetchFiles({ commit, getters }, payload) {
    try {
      commit("filesBegin");
      let response = await ServiceApi.get(
        `/api/v1/colleges/${getters.collageAuthUser.id}/files`,
        payload
      );
      commit("SET_FILES", response.data);
      commit("filesSuccess", true);
      return response;
    } catch (err) {
      commit("filesErr", err);
    }
  },
  async uploadFile({ commit, getters }, payload) {
    let dataForm = new FormData();
    dataForm.append("name", payload.name);
    dataForm.append("file", payload.file);
    dataForm.append("category_id", payload.category_id);
    try {
      commit("filesBegin");
      const resp = await ServiceApi.post(
        `/api/v1/colleges/${getters.collageAuthUser.id}/files/add`,
        dataForm
      );
      commit("filesSuccess", true);
      return resp;
    } catch (err) {
      commit("filesErr", err);
    }
  },
  async deleteFiles({ commit }, payload) {
    try {
      commit("filesBegin");
      const resp = await ServiceApi.delete(
        `/api/v1/colleges/${payload}/files/delete`
      );
      commit("filesSuccess", true);
      return resp;
    } catch (err) {
      commit("filesErr", err);
    }
  },
};

const getters = {
  getFiles: (state) => state.filesState,
  filesLoading: (state) => state.loading,
};

const mutations = {
  SET_FILES(state, payload) {
    state.filesState = payload;
  },
  SET_DELETE_COURSES(state, payload) {
    state.filesState = state.filesState.filter((item) => item.id !== payload);
  },
  filesBegin(state) {
    state.loading = true;
  },
  filesSuccess(state) {
    state.loading = false;
  },
  filesErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
