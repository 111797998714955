export default {
  /* Filtering the array of objects by the role id. */
  filterUserCollegeRoles(role, arr) {
    return arr.map(item => ({
      id: item.user.id,
      name: item.user.name,
      roles: item.roles.find(item => item.id == role) || {},
      user: item.user
    })).filter(item => item.roles.id == role)
  },

  filterUserDuplicate(arr, compare) {
    /* Filtering the array of objects by the role id. */
    arr.map((firstObj) => {
      compare.map((compareObj, i) => {
        if (firstObj.name === compareObj.name) {
          compare.splice(i, 1);
        }
      })
    })
    return compare
  }
};
