import store from "../store/index";
import check from "../services/check-auth";

export const authGuard = async (to, from, next) => {
  const publicPages = [
    "/auth/login",
    "/auth/sign-up1",
    "/auth/password-reset1",
    "/dark/auth/login",
    "/dark/auth/sign-up1",
  ];
  const authRequired = !publicPages.includes(to.path);
  if (authRequired) {
    await check();
  }
  const loggedIn = store.getters["user"];
  if (to.meta.auth) {
    if (authRequired && !loggedIn) {
      return next("/auth/login");
    } else if (to.name === "dashboard" || to.name === "dashboard.home-1") {
      return next();
    }
  } else {
    return next();
  }
  next();
};
