import { ServiceApi } from "@/services/auth";

const state = {
  contracts: [],
  contract: null,
  loading: false,
  error: null,
};

const actions = {
  async fetchContracts({ commit }, payload) {
    try {
      commit("contractsBegin");
      let response = await ServiceApi.get("/api/v1/ad-contracts", payload);
      commit("SET_CONTRACTS", response.data);
      commit("contractsSuccess", true);
      return response;
    } catch (err) {
      commit("contractsErr", err);
    }
  },
  async fetchContract({ commit }, payload) {
    try {
      commit("contractsBegin");
      let response = await ServiceApi.get(`/api/v1/ad-contracts/${payload}`);
      commit("SET_CONTRACT", response.data);
      commit("contractsSuccess", true);
      return response;
    } catch (err) {
      commit("contractsErr", err);
    }
  },
  async createOrUpdateContract({ commit, dispatch }, payload) {
    try {
      commit("contractsBegin");
      if (!payload.id) {
        const resp = await ServiceApi.post(`/api/v1/ad-contracts`, payload);
        commit("contractsSuccess", true);
        commit("SET_NEW_CONTRACT", resp.data);
        return resp;
      } else if (payload.id) {
        const resp = await ServiceApi.put(
          `/api/v1/ad-contracts/${payload.id}`,
          payload
        );
        commit("contractsSuccess", true);
        dispatch("fetchContracts", {
          company_id: payload.company_id,
        });
        return resp;
      }
    } catch (err) {
      commit("contractsErr", err);
    }
  },
  async deleteContract({ commit }, payload) {
    try {
      commit("contractsBegin");
      const resp = await ServiceApi.delete(`/api/v1/ad-contracts/${payload}`);
      commit("SPLICE_CONTRACT_DELETED", payload);
      commit("contractsSuccess", true);
      return resp;
    } catch (err) {
      commit("contractsErr", err);
    }
  },
};

const getters = {
  contracts: (state) => state.contracts,
  contract: (state) => state.contract,
  contractsLoading: (state) => state.loading,
};

const mutations = {
  SET_CONTRACTS(state, payload) {
    state.contracts = payload;
  },
  SET_CONTRACT(state, payload) {
    state.contract = payload;
  },
  SET_NEW_CONTRACT(state, payload) {
    state.contracts.unshift(payload);
  },
  SPLICE_CONTRACT_DELETED(state, payload) {
    state.contracts = state.contracts.filter((item) => item.id !== payload);
  },
  contractsBegin(state) {
    state.loading = true;
  },
  contractsSuccess(state) {
    state.loading = false;
  },
  contractsErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
