import { ServiceApi } from '@/services/auth'

const state = {
  educationState: [],
  loading: false,
  error: null
}

const actions = {
  async fetchEducation({ commit }, payload) {
    try {
      commit('educationBegin')
      let response = await ServiceApi.get('/api/v1/types/education', payload)
      commit('SET_EDUCATION', response.data)
      commit('educationSuccess', true)
      return response
    } catch (err) {
      commit('educationErr', err)
    }
  },
  async storeEducation({ commit, dispatch }, payload) {
    try {
      commit('educationBegin')
      if (!payload.id) {
        const resp = await ServiceApi.post('/api/v1/types/education', payload)
        commit('educationSuccess', true)
        return resp
      } else if (payload.id) {
        const resp = await ServiceApi.put(`/api/v1/types/education/${payload.id}`, payload)
        commit('educationSuccess', true)
        return resp
      }
    } catch (err) {
      commit('educationErr', err)
    }
  },
  async deleteEducation({ commit }, payload) {
    try {
      commit('educationBegin')
      const resp = await ServiceApi.delete(`/api/v1/types/education/${payload}`)
      commit('educationSuccess', true)
      return resp
    } catch (err) {
      commit('educationErr', err)
    }
  },
}

const getters = {
  getEducation: (state) => state.educationState,
  educationLoading: (state) => state.loading
}

const mutations = {
  SET_EDUCATION(state, payload) {
    state.educationState = payload
  },
  SET_NEW_EDUCATION(state, payload) {
    state.educationState.unshift(payload)
  },
  SET_DELETE_EDUCATION(state, payload) {
    state.educationState = state.educationState.filter(
      item => item.id !== payload
    );
  },
  educationBegin(state) {
    state.loading = true
  },
  educationSuccess(state) {
    state.loading = false
  },
  educationErr(state, err) {
    state.loading = false
    state.error = err
  },
}

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters
}
