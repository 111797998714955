import { ServiceApi } from "@/services/auth";

const state = {
  postState: [],
  postGallery: [],
  loading: false,
  error: null,
};

const actions = {
  async fetchBlogCollege({ commit, getters }, payload) {
    try {
      commit("blogBegin");
      let response = await ServiceApi.get(
        `/api/v1/colleges/posts/${getters.collageAuthUser.id}/all/posts`,
        payload
      );
      commit("SET_POST_BY_BLOG", response.data);
      commit("blogSuccess", true);
      return response;
    } catch (err) {
      commit("blogErr", err);
    }
  },
  async fetchBlogGallery({ commit }, payload) {
    try {
      commit("blogBegin");
      let response = await ServiceApi.get(
        `/api/v1/colleges/posts/${payload.id}/gallery`
      );
      commit("SET_POST_GALLERY", response.data);
      commit("blogSuccess", true);
      return response;
    } catch (err) {
      commit("blogErr", err);
    }
  },
  async storeBlog({ commit, dispatch, getters }, payload) {
    try {
      let dataForm = new FormData();
      for (let key in payload) {
        if (typeof payload[key] === "object") {
          for (let subKey in payload[key]) {
            dataForm.append(`${key}[${subKey}]`, payload[key][subKey]);
          }
        } else {
          if (key !== "image") {
            dataForm.append(key, payload[key]);
          }
        }
      }
      if (payload.image) {
        dataForm.append("image", payload.image);
      }
      commit("blogBegin");
      if (!payload.id) {
        const resp = await ServiceApi.post(
          `/api/v1/colleges/posts/${getters.collageAuthUser.id}/create`,
          dataForm
        );
        commit("blogSuccess", true);
        return resp;
      } else if (payload.id) {
        dataForm.append("_method", "PUT");
        const resp = await ServiceApi.post(
          `/api/v1/colleges/posts/${payload.id}/update`,
          dataForm
        );
        commit("blogSuccess", true);
        return resp;
      }
    } catch (err) {
      console.log(err);
      commit("blogErr", err);
    }
  },
  async addImageGallery({ commit, dispatch, getters }, payload) {
    try {
      let dataForm = new FormData();
      dataForm.append("image", payload.image);
      commit("blogBegin");
      const resp = await ServiceApi.post(
        `/api/v1/colleges/posts/${payload.id}/gallery/add`,
        dataForm
      );
      commit("blogSuccess", true);
      return resp;
    } catch (err) {
      console.log(err);
      commit("blogErr", err);
    }
  },
  async deleteImageGallery({ commit }, payload) {
    try {
      commit("blogBegin");
      const resp = await ServiceApi.delete(
        `/api/v1/colleges/posts/${payload}/gallery/delete`
      );
      commit("blogSuccess", true);
      return resp;
    } catch (err) {
      commit("blogErr", err);
    }
  },
  async deleteBlog({ commit }, payload) {
    try {
      commit("blogBegin");
      const resp = await ServiceApi.delete(
        `/api/v1/colleges/posts/${payload}/delete`
      );
      commit("blogSuccess", true);
      return resp;
    } catch (err) {
      commit("blogErr", err);
    }
  },
};

const getters = {
  getPost: (state) => state.postState,
  getPostGallery: (state) => state.postGallery,
  blogLoading: (state) => state.loading,
};

const mutations = {
  SET_POST_BY_BLOG(state, payload) {
    state.postState = payload;
  },
  SET_POST_GALLERY(state, payload) {
    state.postGallery = payload;
  },
  SET_NEW_POST(state, payload) {
    state.postState.data.unshift(payload);
  },
  blogBegin(state) {
    state.loading = true;
  },
  blogSuccess(state) {
    state.loading = false;
  },
  blogErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
