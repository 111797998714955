import { ServiceApi } from "@/services/auth";

const state = {
  adServices: [],
  loading: false,
  error: null,
};

const actions = {
  async fetchAdServices({ commit }) {
    try {
      let response = await ServiceApi.get("/api/v1/ad-services");
      commit("SET_AD_SERVICES", response.data);
      return response;
    } catch (err) {
      commit("servicesErr", err);
    }
  },
};

const getters = {
  adServices: (state) => state.adServices,
};

const mutations = {
  SET_AD_SERVICES(state, payload) {
    state.adServices = payload;
  },
  servicesErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
