import store from '../store/index'

export default async () => {
  if (!store.getters['check']) {
    try {
      const { data } = await store.dispatch('fetchUser')
      return data
    } catch (e) {
      return e
    }
  } else {
    return store.getters['user']
  }
}
