/* Layouts */
const Layout2 = () => import("../layouts/Layout2.vue");
const AuthLayout = () => import("../layouts/AuthLayouts/AuthLayout");
const BlankLayout = () => import("../layouts/BlankLayout");
const Dashboard3 = () => import("../views/Dashboards");
const SignIn1 = () => import("../views/Auth/SignIn");
const SignUp1 = () => import("../views/Auth/SignUp");
const RecoverPassword1 = () => import("../views/Auth/ForgotPassword");
const PasswordReset = () => import("../views/Auth/RestorePassword");

/* User View */
const ProfileEdit = () => import("../views/User/ProfileEdit");

/* Super Admin */
const TypeEducation = () => import("@/views/SuperAdmin/EducationType");
const Grades = () => import("@/views/SuperAdmin/Grades");
const Categories = () => import("@/views/SuperAdmin/Categories");
const Schools = () => import("@/views/SuperAdmin/Schools");
const Periods = () => import("@/views/SuperAdmin/Periods");

// ADMINISTRATIVE
const Courses = () => import("@/views/Admin/Courses");
const Files = () => import("@/views/Admin/Files");
const ShowCourses = () => import("@/views/Admin/Courses/Show");
const UserCollege = () => import("@/views/Admin/UserCollege");
const CollegeIntegrations = () => import("@/views/Admin/Integrations");
const ChatTemplates = () => import("@/views/Admin/ChatTemplates");
const Teachers = () => import("@/views/Admin/Teachers");
const Administrative = () => import("@/views/Admin/Administrative");
const Representatives = () => import("@/views/Admin/Representatives");
const Students = () => import("@/views/Admin/Students");

// NOTIFICATIONS
const NotificationIndex = () => import("@/views/Notifications");

// EVENTS
const EventsIndex = () => import("@/views/Events");

// BLOG
const BlogIndex = () => import("@/views/Blog");

// Nursing
const MessengerIndex = () => import("@/views/Messenger");

// Nursing
const NursingIndex = () => import("@/views/Nursing");

const generalRoutes = (prop) => [
  {
    path: "privacy-policies",
    name: prop + ".privacy-policies",
    meta: { auth: false },
    component: () => import("@/views/PrivacyPolicy"),
  },
];

const authChildRoutes = (prop) => [
  {
    path: "login",
    name: prop + ".login",
    meta: { auth: false },
    component: SignIn1,
  },
  {
    path: "sign-up1",
    name: prop + ".sign-up1",
    meta: { auth: false },
    component: SignUp1,
  },
  {
    path: "password-reset1",
    name: prop + ".password-reset1",
    meta: { auth: false },
    component: RecoverPassword1,
  },
];

const passwordRoutes = (prop) => [
  {
    path: "reset/:token",
    name: prop + ".reset",
    meta: { auth: false },
    component: PasswordReset,
  },
];

const SuperAdminChildRoutes = (prop) => [
  {
    path: "type-education",
    name: prop + ".type-education",
    meta: { auth: true, name: "Type-education" },
    component: TypeEducation,
  },
  {
    path: "schools",
    name: prop + ".schools",
    meta: { auth: true, name: "Schools" },
    component: Schools,
  },
  {
    path: "schools/edit/:id",
    name: prop + ".schools-edit",
    meta: { auth: true, name: "Schools-Edit" },
    component: () => import("@/views/SuperAdmin/Schools/Edit"),
  },
  {
    path: "grades",
    name: prop + ".grades",
    meta: { auth: true, name: "grades" },
    component: Grades,
  },
  {
    path: "categories",
    name: prop + ".categories",
    meta: { auth: true, name: "categories" },
    component: Categories,
  },
  {
    path: "periods",
    name: prop + ".periods",
    meta: { auth: true, name: "periods" },
    component: Periods,
  },
  {
    path: "corporations",
    name: prop + ".corporations",
    meta: { auth: true, name: "corporations" },
    component: () => import("@/views/Corporations"),
  },
  {
    path: "corporations/:id",
    name: prop + ".show-corporation",
    meta: { auth: true, name: "show-corporation" },
    component: () => import("@/views/Corporations/Show"),
  },
  {
    path: "companies",
    name: prop + ".companies",
    meta: { auth: true, name: "companies" },
    component: () => import("@/views/Companies"),
  },
  {
    path: "modules",
    name: prop + ".modules",
    meta: { auth: true, name: "modules" },
    component: () => import("@/views/Modules"),
  },
  {
    path: "companies/:id",
    name: prop + ".show-company",
    meta: { auth: true, name: "show-company" },
    component: () => import("@/views/Companies/Show"),
  },
  {
    path: "companies/:companyId/ad-contracts/:id",
    name: prop + ".show-contract",
    meta: { auth: true, name: "show-contract" },
    component: () => import("@/views/Companies/Contracts/Show"),
  },
];

const adminChildRoutes = (prop) => [
  {
    path: "courses",
    name: prop + ".courses",
    meta: { auth: true, name: "courses" },
    component: Courses,
  },
  {
    path: "show-courses/:id",
    name: prop + ".show-courses",
    meta: { auth: true, name: "show-courses" },
    component: ShowCourses,
  },
  {
    path: "user-college",
    name: prop + ".user-college",
    meta: { auth: true, name: "user-college" },
    component: UserCollege,
  },
  {
    path: "admin-college",
    name: prop + ".admin-college",
    meta: { auth: true, name: "admin-college" },
    component: () => import("@/views/Admin/AdminCollege/index"),
  },
  {
    path: "files",
    name: prop + ".files",
    meta: { auth: true, name: "files" },
    component: Files,
  },
  {
    path: "teachers",
    name: prop + ".teachers",
    meta: { auth: true, name: "Teachers" },
    component: Teachers,
  },
  {
    path: "administrative",
    name: prop + ".administrative",
    meta: { auth: true, name: "Administrative" },
    component: Administrative,
  },
  {
    path: "representatives",
    name: prop + ".representatives",
    meta: { auth: true, name: "Representatives" },
    component: Representatives,
  },
  {
    path: "chat-templates",
    name: prop + ".chat-templates",
    meta: { auth: true, name: "ChatTemplates" },
    component: ChatTemplates,
  },
  {
    path: "integrations",
    name: prop + ".integrations",
    meta: { auth: true, name: "Integrations" },
    component: CollegeIntegrations,
  },
  {
    path: "students",
    name: prop + ".students",
    meta: { auth: true, name: "Students" },
    component: Students,
  },
];

const notifications = (prop) => [
  {
    path: "index",
    name: prop + ".index",
    meta: { auth: true, name: "index" },
    component: NotificationIndex,
  },
];

const events = (prop) => [
  {
    path: "index",
    name: prop + ".index",
    meta: { auth: true, name: "index" },
    component: EventsIndex,
  },
];

const blog = (prop) => [
  {
    path: "index",
    name: prop + ".index",
    meta: { auth: true, name: "index" },
    component: BlogIndex,
  },
];

const messenger = (prop) => [
  {
    path: "index",
    name: prop + ".index",
    meta: { auth: true, name: "index" },
    component: MessengerIndex,
  },
];

const nursing = (prop) => [
  {
    path: "index",
    name: prop + ".index",
    meta: { auth: true, name: "index" },
    component: NursingIndex,
  },
];

const userChildRoute = (prop) => [
  {
    path: "profile-edit",
    name: prop + ".edit",
    meta: { auth: true, name: "Edit Profile" },
    component: ProfileEdit,
  },
];

export const routes = [
  {
    path: "/",
    name: "dashboard.home-1",
    component: Layout2,
    meta: { auth: true },
    children: [
      {
        path: "",
        name: "dashboard.home-3",
        meta: { auth: true, name: "Home 3" },
        component: Dashboard3,
      },
    ],
  },
  {
    path: "/general",
    name: "general",
    component: BlankLayout,
    meta: { auth: false },
    children: generalRoutes("general"),
  },
  {
    path: "/auth",
    name: "auth1",
    component: AuthLayout,
    meta: { auth: false },
    children: authChildRoutes("auth1"),
  },
  {
    path: "/password",
    name: "password",
    component: AuthLayout,
    meta: { auth: true },
    children: passwordRoutes("password"),
  },
  {
    path: "/user",
    name: "user",
    component: Layout2,
    meta: { auth: true },
    children: userChildRoute("user"),
  },
  // Administration
  {
    path: "/administration",
    name: "administration",
    component: Layout2,
    meta: { auth: true },
    children: SuperAdminChildRoutes("admin"),
  },
  //  Super Admin
  {
    path: "/admin",
    name: "admin",
    component: Layout2,
    meta: { auth: true },
    children: adminChildRoutes("administration"),
  },
  // Administration
  {
    path: "/notifications",
    name: "notifications",
    component: Layout2,
    meta: { auth: true },
    children: notifications("notifications"),
  },
  {
    path: "/events",
    name: "events",
    component: Layout2,
    meta: { auth: true },
    children: events("events"),
  },
  {
    path: "/blog",
    name: "blog",
    component: Layout2,
    meta: { auth: true },
    children: blog("blog"),
  },
  {
    path: "/messenger",
    name: "messenger",
    component: Layout2,
    meta: { auth: true },
    children: messenger("messenger"),
  },
  {
    path: "/nursing",
    name: "nursing",
    component: Layout2,
    meta: { auth: true },
    children: nursing("nursing"),
  },
];
