import { ServiceApi } from "@/services/auth";

const state = {
  corporations: [],
  corporation: null,
  loading: false,
  error: null,
};

const actions = {
  async fetchCorporations({ commit }, payload) {
    try {
      commit("corporationsBegin");
      let response = await ServiceApi.get("/api/v1/corporations", payload);
      commit("SET_CORPORATIONS", response.data);
      commit("corporationsSuccess", true);
      return response;
    } catch (err) {
      commit("corporationsErr", err);
    }
  },
  async fetchCorporation({ commit }, payload) {
    try {
      commit("corporationsBegin");
      let response = await ServiceApi.get(`/api/v1/corporations/${payload}`);
      commit("SET_CORPORATION", response.data);
      commit("corporationsSuccess", true);
      return response;
    } catch (err) {
      commit("corporationsErr", err);
    }
  },
  async createOrUpdateCorporation({ commit, dispatch }, payload) {
    let formData = new FormData();
    formData.append("name", payload.name);
    formData.append("business_name", payload.business_name);
    formData.append("rut", payload.rut);
    formData.append("commune_id", payload.commune_id);
    if (payload.avatar) formData.append("avatar", payload.avatar);
    try {
      commit("corporationsBegin");
      if (!payload.id) {
        const resp = await ServiceApi.post(`/api/v1/corporations`, formData);
        commit("corporationsSuccess", true);
        commit("SET_NEW_CORPORATION", resp);
        return resp;
      } else if (payload.id) {
        formData.append("_method", "PUT");
        const resp = await ServiceApi.post(
          `/api/v1/corporations/${payload.id}`,
          formData
        );
        commit("corporationsSuccess", true);
        dispatch("fetchCorporations");
        return resp;
      }
    } catch (err) {
      commit("corporationsErr", err);
    }
  },
  async deleteCorporation({ commit }, payload) {
    try {
      commit("corporationsBegin");
      const resp = await ServiceApi.delete(`/api/v1/corporations/${payload}`);
      commit("SPLICE_CORPORATION_DELETED", payload);
      commit("corporationsSuccess", true);
      return resp;
    } catch (err) {
      commit("corporationsErr", err);
    }
  },
  async addCollegeToCorporation({ commit, dispatch }, payload) {
    try {
      commit("corporationsBegin");
      const resp = await ServiceApi.post(
        `/api/v1/corporations/${payload.corporation_id}/add/college`,
        payload
      );
      dispatch("fetchCorporation", payload.corporation_id);
      commit("corporationsSuccess", true);
      return resp;
    } catch (err) {
      commit("corporationsErr", err);
    }
  },
  async deleteCorporationCollege({ commit, dispatch }, payload) {
    try {
      commit("corporationsBegin");
      const resp = await ServiceApi.post(
        `/api/v1/corporations/${payload.corporation_id}/delete/college`,
        payload
      );
      dispatch("fetchCorporation", payload.corporation_id);
      commit("corporationsSuccess", true);
      return resp;
    } catch (err) {
      commit("corporationsErr", err);
    }
  },
};

const getters = {
  corporations: (state) => state.corporations,
  corporation: (state) => state.corporation,
  corporationsLoading: (state) => state.loading,
};

const mutations = {
  SET_CORPORATIONS(state, payload) {
    state.corporations = payload;
  },
  SET_CORPORATION(state, payload) {
    state.corporation = payload;
  },
  SET_NEW_CORPORATION(state, payload) {
    state.corporations.data.unshift(payload);
  },
  SPLICE_CORPORATION_DELETED(state, payload) {
    state.corporations.data = state.corporations.data.filter(
      (item) => item.id !== payload
    );
  },
  corporationsBegin(state) {
    state.loading = true;
  },
  corporationsSuccess(state) {
    state.loading = false;
  },
  corporationsErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
