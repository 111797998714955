import { ServiceApi } from "@/services/auth";

const state = {
  categoriesState: [],
  loading: false,
  error: null,
};

const actions = {
  async fetchCategories({ commit }, payload) {
    try {
      commit("categoriesBegin");
      let response = await ServiceApi.get("/api/v1/categories", payload);
      commit("SET_CATEGORIES", response.data);
      commit("categoriesSuccess", true);
      return response;
    } catch (err) {
      commit("categoriesErr", err);
    }
  },
  async fetchCategoriesColleges({ commit, getters }, payload) {
    try {
      commit("categoriesBegin");
      let response = await ServiceApi.get(
        `/api/v1/categories/college/${getters.collageAuthUser.id}/all`,
        payload
      );
      commit("SET_CATEGORIES", response.data);
      commit("categoriesSuccess", true);
      return response;
    } catch (err) {
      commit("categoriesErr", err);
    }
  },
  async storeCategory({ commit, dispatch, getters }, payload) {
    try {
      commit("categoriesBegin");
      if (!payload.id) {
        const resp = await ServiceApi.post(`/api/v1/categories`, payload);
        commit("categoriesSuccess", true);
        return resp;
      } else if (payload.id) {
        const resp = await ServiceApi.put(
          `/api/v1/categories/${payload.id}`,
          payload
        );
        commit("categoriesSuccess", true);
        return resp;
      }
    } catch (err) {
      commit("categoriesErr", err);
    }
  },
  async deleteCategory({ commit }, payload) {
    try {
      commit("categoriesBegin");
      const resp = await ServiceApi.delete(`/api/v1/categories/${payload}`);
      commit("SET_DELETE_CATEGORIES", payload);
      commit("categoriesSuccess", true);
      return resp;
    } catch (err) {
      commit("categoriesErr", err);
    }
  },
};

const getters = {
  getCategories: (state) => state.categoriesState,
  categoriesLoading: (state) => state.loading,
};

const mutations = {
  SET_CATEGORIES(state, payload) {
    state.categoriesState = payload;
  },
  SET_NEW_CATEGORIES(state, payload) {
    state.categoriesState.data.unshift(payload);
  },
  SET_DELETE_CATEGORIES(state, payload) {
    state.categoriesState.data = state.categoriesState.data.filter(
      (item) => item.id !== payload
    );
  },
  categoriesBegin(state) {
    state.loading = true;
  },
  categoriesSuccess(state) {
    state.loading = false;
  },
  categoriesErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
