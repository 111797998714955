import { ServiceApi } from "@/services/auth";
import Filters from "@/Utils/Filters";

const state = {
  coursesState: [],
  loading: false,
  error: null,
  coursesDetails: {},
  teachersCourse: [],
  studentsCourse: [],
};

const actions = {
  async fetchCourses({ commit, getters }, payload) {
    try {
      commit("coursesBegin");
      let response = await ServiceApi.get("/api/v1/courses", {
        ...payload,
        college: getters.collageAuthUser.id,
      });
      commit("SET_COURSES", response.data);
      commit("coursesSuccess", true);
      return response;
    } catch (err) {
      commit("coursesErr", err);
    }
  },
  async cleanListCourses({ commit }) {
    commit("SET_COURSES", []);
  },
  async showCourses({ commit }, id) {
    try {
      commit("coursesBegin");
      let response = await ServiceApi.get(`/api/v1/courses/${id}`);
      commit("SET_COURSES_DETAILS", response.data);
      commit("coursesSuccess", true);
      return response;
    } catch (err) {
      commit("coursesErr", err);
    }
  },
  async storeCourses({ commit, dispatch }, payload) {
    try {
      commit("coursesBegin");
      if (!payload.id) {
        const resp = await ServiceApi.post("/api/v1/courses", payload);
        commit("SET_NEW_COURSES", resp.data);
        commit("coursesSuccess", true);
        return resp;
      } else if (payload.id) {
        const resp = await ServiceApi.put(
          `/api/v1/courses/${payload.id}`,
          payload
        );
        await dispatch("fetchCourses");
        commit("coursesSuccess", true);
        return resp;
      }
    } catch (err) {
      commit("coursesErr", err);
    }
  },
  async deleteCourses({ commit }, payload) {
    try {
      commit("coursesBegin");
      const resp = await ServiceApi.delete(`/api/v1/courses/${payload}`);
      commit("SET_DELETE_COURSES", payload);
      commit("coursesSuccess", true);
      return resp;
    } catch (err) {
      commit("coursesErr", err);
    }
  },

  // USERS IN COURSES

  async addUserCourses({ commit, dispatch }, payload) {
    try {
      commit("coursesBegin");
      const resp = await ServiceApi.post(
        `/api/v1/courses/${payload.course}/add/user`,
        payload.data
      );
      await dispatch("showCourses", payload.course);
      commit("coursesSuccess", true);
      return resp;
    } catch (err) {
      commit("coursesErr", err);
    }
  },
  async deleteUserCourses({ commit, dispatch }, { course, user }) {
    try {
      commit("coursesBegin");
      const resp = await ServiceApi.delete(
        `/api/v1/courses/${course}/${user}/delete/user`
      );
      await dispatch("showCourses", course);
      commit("coursesSuccess", true);
      return resp;
    } catch (err) {
      commit("coursesErr", err);
    }
  },

  // MASSIVE LOAD
  async uploadTeacher({ commit, dispatch }, payload) {
    try {
      commit("coursesBegin");
      const dataEdit = new FormData();
      dataEdit.append("file", payload.file);
      dataEdit.append("origin", payload.origin);
      const url =
        payload.type === 0
          ? "/api/v1/colleges/upload/teachers/file"
          : "/api/v1/colleges/upload/attorneys/file";
      const resp = await ServiceApi.post(url, dataEdit);
      await dispatch("fetchUserCollege");
      commit("coursesSuccess", true);
      return resp;
    } catch (err) {
      commit("coursesErr", err);
    }
  },
  async uploadStudents({ commit, dispatch }, payload) {
    try {
      commit("coursesBegin");
      const dataEdit = new FormData();
      dataEdit.append("file", payload.file);
      dataEdit.append("origin", payload.origin);
      const resp = await ServiceApi.post(
        `/api/v1/courses/${payload.course}/upload/users/file`,
        dataEdit
      );
      await dispatch("showCourses", payload.course);
      commit("coursesSuccess", true);
      return resp;
    } catch (err) {
      commit("coursesErr", err);
    }
  },
};

const getters = {
  getCourses: (state) => state.coursesState,
  getCoursesDetails: (state) => state.coursesDetails,
  getTeacherCourses: (state) => state.teachersCourse,
  getStudentsCourses: (state) => state.studentsCourse,
  coursesLoading: (state) => state.loading,
};

const mutations = {
  SET_COURSES(state, payload) {
    state.coursesState = payload;
  },
  SET_COURSES_DETAILS(state, payload) {
    state.coursesDetails = payload;
    (state.teachersCourse = Filters.filterUserCollegeRoles(
      4,
      payload.usersCourse
    )),
      (state.studentsCourse = Filters.filterUserCollegeRoles(
        3,
        payload.usersCourse
      ));
  },
  SET_NEW_COURSES(state, payload) {
    state.coursesState.unshift(payload);
  },
  SET_DELETE_COURSES(state, payload) {
    state.coursesState = state.coursesState.filter(
      (item) => item.id !== payload
    );
  },
  coursesBegin(state) {
    state.loading = true;
  },
  coursesSuccess(state) {
    state.loading = false;
  },
  coursesErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
