import { ServiceApi } from "../../services/auth";

const state = {
  usersAll: [],
  loading: false,
  error: null,
};

const actions = {
  async fetchUsersAll({ commit }, payload = {}) {
    try {
      commit("usersBegin");
      const response = await ServiceApi.get(`/api/v1/users`, payload);
      commit("SET_USERS", response);
      commit("usersSuccess", true);
      return response;
    } catch (err) {
      commit("userErr", err);
    }
  },
  async createUser({ commit, dispatch }, payload) {
    try {
      commit("usersBegin");
      const response = await ServiceApi.post("/api/v1/users", payload);
      await dispatch("fetchUsersAll");
      commit("usersSuccess", true);
      return response;
    } catch (err) {
      commit("userErr", err);
    }
  },
};

const getters = {
  usersAll: (state) => state.usersAll,
  usersLoading: (state) => state.loading,
};

const mutations = {
  SET_USERS(state, payload) {
    state.usersAll = payload.data;
  },
  usersBegin(state) {
    state.loading = true;
  },
  usersSuccess(state) {
    state.loading = false;
  },
  userErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
