import { setAccessToken } from "../../Utils/manageCookies";
import { ServiceApi } from "../../services/auth";

const state = {
  user: null,
  loading: false,
  error: null,
  collageAuth: null,
  collageSession: [],
};

const actions = {
  async login({ commit, dispatch }, payload) {
    try {
      commit("loginBegin");
      const response = await ServiceApi.post("api/login-mobile", payload);
      if(response.data?.token){
        setAccessToken(response.data.token)
      }
      commit("loginSuccess", true);
      await dispatch("fetchUser");
      return response;
    } catch (err) {
      commit("loginErr", err);
      return err;
    }
  },
  async fetchUser({ commit, dispatch }) {
    try {
      const data = (await ServiceApi.get("api/v1/user")).data;
      await dispatch("sesionCollageAuth");
      commit("setUser", data && data.id ? data : undefined);
      return data;
    } catch (error) {
      commit("setUser", null);
      return error;
    }
  },
  async logout({ commit }) {
    try {
      commit("logoutBegin");
      const data = await ServiceApi.post("api/logout-mobile");
      setAccessToken(null)
      commit("setUser", null);
      commit("logoutSuccess", null);
      return data;
    } catch (err) {
      commit("logoutErr", err);
    }
  },
  async forgotPassword({ commit }, payload) {
    try {
      commit("logoutBegin");
      const resp = await ServiceApi.post("api/forgot-password-user", payload);
      commit("loginSuccess", true);
      return resp;
    } catch (error) {
      commit("logoutErr", error);
      return error;
    }
  },
  async restorePassword({ commit }, payload) {
    try {
      commit("logoutBegin");
      const resp = await ServiceApi.post("api/reset-password-user", payload);
      commit("loginSuccess", true);
      return resp;
    } catch (error) {
      return error;
    }
  },
  async register({ commit }, payload) {
    try {
      commit("loginBegin");
      const response = await ServiceApi.post("/api/register", payload);
      commit("loginSuccess", true);
      return response;
    } catch (err) {
      commit("loginErr", err);
    }
  },

  // PROFILE CURRENT USER

  async updateProfile({ commit, dispatch }, payload) {
    try {
      commit("loginBegin");
      let dataEdit = new FormData();
      dataEdit.append("_method", "PUT");
      dataEdit.append("name", payload.name);
      dataEdit.append("first_lastname", payload.first_lastname);
      dataEdit.append("second_lastname", payload.second_lastname);
      dataEdit.append("email", payload.email);
      dataEdit.append("rut", payload.rut);
      dataEdit.append("phone", payload.phone);
      dataEdit.append("birthdate", payload.birthdate);
      if (payload.avatar) {
        dataEdit.append("avatar", payload.avatar);
      }

      const response = await ServiceApi.post(
        "/api/v1/users/update/profile",
        dataEdit
      );
      await dispatch("fetchUser");
      commit("loginSuccess", true);
      return response;
    } catch (err) {
      commit("loginErr", err);
    }
  },

  async updatePassword({ commit, dispatch }, payload) {
    try {
      commit("loginBegin");
      const response = await ServiceApi.put(
        "/api/v1/users/update/password",
        payload
      );
      await dispatch("fetchUser");
      commit("loginSuccess", true);
      return response;
    } catch (err) {
      commit("loginErr", err);
    }
  },

  async updateLang({ commit, dispatch }, lang) {
    try {
      commit("loginBegin");
      const response = await ServiceApi.put(`/api/v1/lang/${lang}`);
      await dispatch("fetchUser");
      commit("loginSuccess", true);
      return response;
    } catch (err) {
      commit("loginErr", err);
    }
  },

  // COLLAGE USER
  async sesionCollageAuth({ commit }, payload) {
    try {
      commit("loginBegin");
      const response = await ServiceApi.get("/api/v1/session/college", payload);
      commit("SET_USER_COLLAGE_AUTH", response.data);
      commit("loginSuccess", true);
      return response;
    } catch (err) {
      commit("loginErr", err);
    }
  },

  async collageSesion({ commit }, payload) {
    try {
      commit("loginBegin");
      const response = await ServiceApi.get(
        "/api/v1/session/college/all",
        payload
      );
      commit("SET_USER_COLLAGE_SESION", response.data);
      commit("loginSuccess", true);
      return response;
    } catch (err) {
      commit("loginErr", err);
    }
  },

  async updateCollageSesion({ commit, dispatch }, payload) {
    try {
      commit("loginBegin");
      const response = await ServiceApi.put(`/api/v1/session/college`, payload);
      await dispatch("fetchUser");
      commit("loginSuccess", true);
      return response;
    } catch (err) {
      commit("loginErr", err);
    }
  },
};

const getters = {
  user: (state) => state.user,
  check: (state) => state.user !== null && state.user !== undefined,
  isLoading: (state) => state.loading,
  collageAuthUser: (state) => state.collageAuth,
  collageSession: (state) => state.collageSession,
};

const mutations = {
  SET_USER_COLLAGE_AUTH(state, payload) {
    state.collageAuth = payload;
  },
  SET_USER_COLLAGE_SESION(state, payload) {
    state.collageSession = payload;
  },
  setUser(state, user) {
    state.user = user;
  },
  loginBegin(state) {
    state.loading = true;
  },
  loginSuccess(state) {
    state.loading = false;
  },

  loginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  logoutBegin(state) {
    state.loading = true;
  },

  logoutSuccess(state) {
    state.loading = false;
  },

  logoutErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
