import { ServiceApi } from "../../services/auth";

const state = {
  notifications: [],
  notificationsAll: [],
  notificationsSended: [],
  loading: false,
  error: null,
};

const actions = {
  /* A function that is called from the component. */
  async fetchNotifications({ commit }, payload) {
    try {
      commit("notificationsBegin");
      const response = await ServiceApi.get(
        "/api/v1/notifications/user/auth",
        payload
      );
      commit("SET_NOTIFICATIONS", response);
      commit("notificationsSuccess", true);
      return response;
    } catch (err) {
      commit("notificationsErr", err);
    }
  },

  /* A function that is called from the component. */
  async fetchAllNotifications({ commit, getters }, payload) {
    try {
      commit("notificationsBegin");
      const response = await ServiceApi.get(
        `/api/v1/notifications?model=${getters.collageAuthUser.id}`,
        payload
      );
      commit("SET_ALL_NOTIFICATIONS", response);
      commit("notificationsSuccess", true);
      return response;
    } catch (err) {
      commit("notificationsErr", err);
    }
  },

  /* A function that is called from the component. */
  async fetchNotificationsSendedUser({ commit, getters }, payload) {
    try {
      commit("notificationsBegin");
      const response = await ServiceApi.get(
        `/api/v1/notifications/sended/user/auth?model=${getters.collageAuthUser.id}`,
        payload
      );
      commit("SET_SENDED_NOTIFICATIONS", response);
      commit("notificationsSuccess", true);
      return response;
    } catch (err) {
      commit("notificationsErr", err);
    }
  },

  /* A function that is called from the component. */
  async storeNotifications({ commit, dispatch }, payload) {
    try {
      commit("notificationsBegin");
      const response = await ServiceApi.post("/api/v1/notifications", payload);
      commit("SET_STORE_NOTIFICATIONS", response);
      dispatch("fetchNotificationsSendedUser");
      commit("notificationsSuccess", true);
      return response;
    } catch (err) {
      commit("notificationsErr", err);
    }
  },
  /* A function that is called from the component. */
  async markNotifications({ commit, dispatch }, payload) {
    try {
      commit("notificationsBegin");
      const response = await ServiceApi.post(
        `/api/v1/notifications/mark/read`,
        payload
      );
      dispatch("fetchNotifications");
      commit("notificationsSuccess", true);
      return response;
    } catch (err) {
      commit("notificationsErr", err);
    }
  },
};

const getters = {
  getNotifications: (state) => state.notifications,
  getAllNotifications: (state) => state.notificationsAll,
  getSendedNotifications: (state) => state.notificationsSended,
  notificationsLoading: (state) => state.loading,
};

const mutations = {
  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload.data;
  },
  SET_ALL_NOTIFICATIONS(state, payload) {
    state.notificationsAll = payload.data;
  },
  SET_SENDED_NOTIFICATIONS(state, payload) {
    state.notificationsSended = payload.data;
  },
  SET_STORE_NOTIFICATIONS(state, payload) {
    state.notificationsAll.unshift(payload.data);
  },
  notificationsBegin(state) {
    state.loading = true;
  },
  notificationsSuccess(state) {
    state.loading = false;
  },
  notificationsErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
