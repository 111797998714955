import { ServiceApi } from "@/services/auth";

const state = {
  gradesState: [],
  loading: false,
  error: null,
};

const actions = {
  async fetchGrades({ commit }, payload) {
    commit("SET_GRADES", []);
    try {
      commit("gradesBegin");
      let response = await ServiceApi.get("/api/v1/grades", payload);
      commit("SET_GRADES", response.data);
      commit("gradesSuccess", true);
      return response;
    } catch (err) {
      commit("gradesErr", err);
    }
  },
  async storeGrades({ commit, dispatch }, payload) {
    try {
      commit("gradesBegin");
      if (!payload.id) {
        const resp = await ServiceApi.post("/api/v1/grades", payload);
        commit("SET_NEW_GRADES", resp.data);
        commit("gradesSuccess", true);
        return resp;
      } else if (payload.id) {
        const resp = await ServiceApi.put(
          `/api/v1/grades/${payload.id}`,
          payload
        );
        await dispatch("fetchGrades");
        commit("gradesSuccess", true);
        return resp;
      }
    } catch (err) {
      commit("gradesErr", err);
    }
  },
  async deleteGrades({ commit }, payload) {
    try {
      commit("gradesBegin");
      const resp = await ServiceApi.delete(`/api/v1/grades/${payload}`);
      commit("SET_DELETE_GRADES", payload);
      commit("gradesSuccess", true);
      return resp;
    } catch (err) {
      commit("gradesErr", err);
    }
  },
};

const getters = {
  getGrades: (state) => state.gradesState,
  gradesLoading: (state) => state.loading,
};

const mutations = {
  SET_GRADES(state, payload) {
    state.gradesState = payload;
  },
  SET_NEW_GRADES(state, payload) {
    state.gradesState.unshift(payload);
  },
  SET_DELETE_GRADES(state, payload) {
    state.gradesState = state.gradesState.filter((item) => item.id !== payload);
  },
  gradesBegin(state) {
    state.loading = true;
  },
  gradesSuccess(state) {
    state.loading = false;
  },
  gradesErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
