import { ServiceApi } from "@/services/auth";

const state = {
  collegeGoogleCalendars: [],
  loading: false,
  loadingSync: false,
  error: null,
};

const actions = {
  async fetchCollegeGoogleCalendars({ commit, getters }, payload) {
    try {
      commit("calendarBegin");
      let response = await ServiceApi.get(
        `/api/v1/colleges/calendars/${getters.collageAuthUser.id}/all`,
        payload
      );
      commit("SET_COLLEGE_GOOGLE_CALENDARS", response.data);
      commit("calendarSuccess");
      return response;
    } catch (err) {
      commit("calendarErr", err);
    }
  },
  async updateOrCreateCollegeGoogleCalendar(
    { commit, getters, dispatch },
    payload
  ) {
    try {
      commit("calendarBegin");
      if (!payload.id) {
        const resp = await ServiceApi.post(
          `/api/v1/colleges/calendars/${getters.collageAuthUser.id}/create`,
          payload
        );
        commit("SET_NEW_COLLEGE_GOOGLE_CALENDAR", resp.data);
        commit("calendarSuccess");
        return resp;
      } else if (payload.id) {
        const resp = await ServiceApi.put(
          `/api/v1/colleges/calendars/${payload.id}/update`,
          payload
        );
        await dispatch("fetchCollegeGoogleCalendars");
        commit("calendarSuccess");
        return resp;
      }
    } catch (err) {
      commit("collegesErr", err);
    }
  },
  async syncCollegeGoogleCalendarEvents({ commit, dispatch }, payload) {
    try {
      commit("calendarBeginSync");
      const resp = await ServiceApi.put(
        `/api/v1/events/college/${payload.id}/sync`
      );
      commit("calendarSuccessSync");
      return resp;
    } catch (err) {
      commit("calendarErr", err);
    }
  },
  async deleteCollegeGoogleCalendar({ commit }, payload) {
    try {
      commit("calendarBegin");
      const resp = await ServiceApi.delete(
        `/api/v1/colleges/calendars/${payload}/delete`
      );
      commit("SET_DELETE_COLLEGE_GOOGLE_CALENDAR", payload);
      commit("calendarSuccess");
      return resp;
    } catch (err) {
      commit("calendarErr", err);
    }
  },
};

const getters = {
  collegeGoogleCalendars: (state) => state.collegeGoogleCalendars,
  calendarLoading: (state) => state.loading,
  calendarLoadingSync: (state) => state.loadingSync,
};

const mutations = {
  SET_COLLEGE_GOOGLE_CALENDARS(state, payload) {
    state.collegeGoogleCalendars = payload;
  },
  SET_NEW_COLLEGE_GOOGLE_CALENDAR(state, payload) {
    state.collegeGoogleCalendars.unshift(payload);
  },
  SET_DELETE_COLLEGE_GOOGLE_CALENDAR(state, payload) {
    state.collegeGoogleCalendars = state.collegeGoogleCalendars.filter(
      (item) => item.id !== payload
    );
  },
  calendarBegin(state) {
    state.loading = true;
  },
  calendarSuccess(state) {
    state.loading = false;
  },
  calendarBeginSync(state) {
    state.loadingSync = true;
  },
  calendarSuccessSync(state) {
    state.loadingSync = false;
  },
  calendarErr(state, err) {
    state.loading = false;
    state.loadingSync = false;
    state.error = err;
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
