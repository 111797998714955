import { ServiceApi } from "@/services/auth";
import Filters from "../../../Utils/Filters";
import { ROLE_ADMIN_ID } from "@/constants/roles";

const state = {
  userCollegeState: [],
  adminCollegeState: [],
  loading: false,
  error: null,
  userRolesCollage: [],
  loadingRole: false,
  userCollegeTeacher: [],
  userCollegeStudent: [],
};

const actions = {
  async fetchUserCollege({ commit, getters }, payload) {
    try {
      commit("userCollegeBegin");
      let response = await ServiceApi.get(
        `/api/v1/colleges/${getters.collageAuthUser.id}/all/users`,
        payload
      );
      if (payload && payload.roles && payload.roles.some((rol) => rol === ROLE_ADMIN_ID)) {
        commit("SET_ADMIN_COLLEGE", response.data);
      } else {
        commit("SET_USER_COLLEGE", response.data);
      }
      commit("userCollegeSuccess", true);
      return response;
    } catch (err) {
      console.log(err)
      commit("userCollegeErr", err);
    }
  },
  async syncStudentsCollegeColegium({ commit, getters }, payload) {
    try {
      commit("userCollegeBegin");
      let response = await ServiceApi.get(
        `/api/v1/colleges/${getters.collageAuthUser.id}/sync/students/colegium`,
        payload
      );
      commit("userCollegeSuccess", true);
      return response;
    } catch (err) {
      commit("userCollegeErr", err);
    }
  },
  async storeUserCollege({ commit, dispatch, getters }, payload) {
    try {
      commit("userCollegeBegin");
      if (!payload.id) {
        const resp = await ServiceApi.post(
          `/api/v1/colleges/${getters.collageAuthUser.id}/add/user`,
          payload
        );
        await dispatch("fetchUserCollege");
        commit("userCollegeSuccess", true);
        return resp;
      } else if (payload.id) {
        const resp = await ServiceApi.put(
          `/api/v1/colleges/${getters.collageAuthUser.id}/${payload.user_id}/update/roles`,
          payload
        );
        commit("userCollegeSuccess", true);
        return resp;
      }
    } catch (err) {
      console.log(error);
      commit("userCollegeErr", err);
    }
  },
  async deleteUserCollege({ commit, getters }, payload) {
    try {
      commit("userCollegeBegin");
      const resp = await ServiceApi.delete(
        `/api/v1/colleges/${getters.collageAuthUser.id}/${payload}/delete/user`
      );
      commit("SET_DELETE_USER_COLLEGE", payload);
      commit("userCollegeSuccess", true);
      return resp;
    } catch (err) {
      commit("userCollegeErr", err);
    }
  },
  async fetchRolesUserCollege({ commit, getters }, user) {
    try {
      commit("loadingRoles", true);
      let response = await ServiceApi.get(
        `/api/v1/colleges/${getters.collageAuthUser.id}/${user}/roles`
      );
      commit("SET_ROLES_USER_COLLEGE", response.data);
      return response;
    } catch (err) {
      commit("userCollegeErr", err);
    } finally {
      commit("loadingRoles", false);
    }
  },
  async uploadStudentsToCollege({ commit, dispatch }, payload) {
    try {
      commit("userCollegeBegin");
      const dataEdit = new FormData();
      dataEdit.append("file", payload.file);
      const resp = await ServiceApi.post(
        `/api/v1/colleges/upload/students/file`,
        dataEdit
      );
      commit("userCollegeSuccess", true);
      return resp;
    } catch (err) {
      commit("userCollegeErr", err);
    }
  },
};

const getters = {
  getUserCollege: (state) => state.userCollegeState,
  getAdminCollege: (state) => state.adminCollegeState,
  getUserRolesCollage: (state) => state.userRolesCollage,
  userCollegeLoading: (state) => state.loading,
  getLoadingRoles: (state) => state.loadingRole,
  getUserCollegesTeacher: (state) => state.userCollegeTeacher,
  getUserCollegesStudent: (state) => state.userCollegeStudent,
};

const mutations = {
  SET_USER_COLLEGE(state, payload) {
    state.userCollegeState = payload;
    state.userCollegeTeacher = Filters.filterUserCollegeRoles(4, payload);
    state.userCollegeStudent = Filters.filterUserCollegeRoles(3, payload);
  },
  SET_ADMIN_COLLEGE(state, payload) {
    state.adminCollegeState = payload;
  },
  SET_NEW_USER_COLLEGE(state, payload) {
    state.userCollegeState.unshift(payload);
  },
  SET_DELETE_USER_COLLEGE(state, payload) {
    state.userCollegeState = state.userCollegeState.filter(
      (item) => item.id !== payload
    );
  },
  SET_ROLES_USER_COLLEGE(state, payload) {
    state.userRolesCollage = payload;
  },
  userCollegeBegin(state) {
    state.loading = true;
  },
  userCollegeSuccess(state) {
    state.loading = false;
  },
  userCollegeErr(state, err) {
    state.loading = false;
    state.error = err;
  },
  loadingRoles(state, payload) {
    state.loadingRole = payload;
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
