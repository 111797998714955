import { ServiceApi } from "@/services/auth";

const state = {
  nursingReports: [],
  nursingReport: null,
  loading: false,
  error: null,
};

const actions = {
  async fetchNursingReports({ commit, getters }, payload) {
    try {
      commit("nursingBegin");
      let response = await ServiceApi.get(
        `/api/v1/nursing-reports/college/${getters.collageAuthUser.id}/all`,
        payload
      );
      commit("SET_NURSING_REPORTS", response.data);
      commit("nursingSuccess", true);
      return response;
    } catch (err) {
      commit("nursingErr", err);
    }
  },
  async getNursingReport({ commit }, id) {
    try {
      commit("nursingBegin");
      let response = await ServiceApi.get(`/api/v1/nursing-reports/${id}`);
      commit("SET_NURSING_REPORT", response.data);
      commit("nursingSuccess", true);
      return response;
    } catch (err) {
      commit("nursingErr", err);
    }
  },
  async createOrUpdateNursingReport({ commit, dispatch, getters }, payload) {
    let dataForm = new FormData();
    dataForm.append("title", payload.title);
    dataForm.append("user_id", payload.user_id.id);
    if (payload.description)
      dataForm.append("description", payload.description);
    if (payload.image) dataForm.append("image", payload.image);
    try {
      commit("nursingBegin");
      if (!payload.id) {
        const resp = await ServiceApi.post(
          `/api/v1/nursing-reports/college/${getters.collageAuthUser.id}/create/report`,
          dataForm
        );
        commit("SET_NEW_NURSING_REPORT", resp.data);
        commit("nursingSuccess", true);
        return resp;
      } else if (payload.id) {
        const resp = await ServiceApi.put(
          `/api/v1/nursing-reports/${payload.id}`,
          payload
        );
        await dispatch("fetchNursingReports");
        commit("nursingSuccess", true);
        return resp;
      }
    } catch (err) {
      console.log(err);
      commit("nursingERR", err);
    }
  },
  async createNursingReportRecord({ commit, dispatch }, payload) {
    let dataForm = new FormData();
    dataForm.append("description", payload.description);
    if (payload.image) dataForm.append("image", payload.image);
    try {
      commit("nursingBegin");
      const resp = await ServiceApi.post(
        `/api/v1/nursing-reports/${payload.nursing_report_id}/create/record`,
        dataForm
      );
      commit("SET_NEW_NURSING_REPORT_RECORD", resp.data);
      commit("nursingSuccess", true);
      return resp;
    } catch (err) {
      console.log(err);
      commit("nursingERR", err);
    }
  },
  async deleteNursingReport({ commit }, payload) {
    try {
      commit("nursingBegin");
      const resp = await ServiceApi.delete(
        `/api/v1/nursing-reports/${payload}`
      );
      commit("FILTER_DELETE_NURSING_REPORT", payload);
      commit("nursingSuccess", true);
      return resp;
    } catch (err) {
      commit("nursingERR", err);
    }
  },
  async deleteNursingReportRecord({ commit }, payload) {
    try {
      commit("nursingBegin");
      const resp = await ServiceApi.delete(
        `/api/v1/nursing-reports/records/${payload}`
      );
      commit("FILTER_DELETE_NURSING_REPORT_RECORD", payload);
      commit("nursingSuccess", true);
      return resp;
    } catch (err) {
      commit("nursingERR", err);
    }
  },
};

const getters = {
  nursingReports: (state) => state.nursingReports,
  nursingReport: (state) => state.nursingReport,
  nursingLoading: (state) => state.loading,
};

const mutations = {
  SET_NURSING_REPORTS(state, payload) {
    state.nursingReports = payload;
  },
  SET_NURSING_REPORT(state, payload) {
    state.nursingReport = payload;
  },
  SET_NEW_NURSING_REPORT(state, payload) {
    state.nursingReports.data.unshift(payload);
  },
  SET_NEW_NURSING_REPORT_RECORD(state, payload) {
    state.nursingReport.records.push(payload);
  },
  FILTER_DELETE_NURSING_REPORT(state, payload) {
    state.nursingReports.data = state.nursingReports.data.filter(
      (item) => item.id !== payload
    );
  },
  FILTER_DELETE_NURSING_REPORT_RECORD(state, payload) {
    state.nursingReport.records = state.nursingReport.records.filter(
      (item) => item.id !== payload
    );
  },
  nursingBegin(state) {
    state.loading = true;
  },
  nursingSuccess(state) {
    state.loading = false;
  },
  nursingERR(state, err) {
    state.loading = false;
    state.error = err;
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
