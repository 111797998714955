import Vue from "vue";
import VueRouter from "vue-router";
import { authGuard } from "./auth.guard";
import { routes } from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(authGuard);

export default router;
