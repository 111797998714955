import { ServiceApi } from "@/services/auth";

const state = {
  periodsState: [],
  loading: false,
  error: null,
};

const actions = {
  async fetchPeriods({ commit }, payload) {
    commit("SET_PERIODS", []);
    try {
      commit("periodsBegin");
      let response = await ServiceApi.get("/api/v1/school/periods", payload);
      commit("SET_PERIODS", response.data);
      commit("periodsSuccess", true);
      return response;
    } catch (err) {
      commit("periodsErr", err);
    }
  },
  async storePeriods({ commit, dispatch }, payload) {
    try {
      commit("periodsBegin");
      if (!payload.id) {
        const resp = await ServiceApi.post("/api/v1/school/periods", payload);
        commit("SET_NEW_PERIODS", resp.data);
        commit("periodsSuccess", true);
        return resp;
      } else if (payload.id) {
        const resp = await ServiceApi.put(
          `/api/v1/school/periods/${payload.id}`,
          payload
        );
        await dispatch("fetchPeriods");
        commit("periodsSuccess", true);
        return resp;
      }
    } catch (err) {
      commit("periodsErr", err);
    }
  },
  async deletePeriods({ commit }, payload) {
    try {
      commit("periodsBegin");
      const resp = await ServiceApi.delete(`/api/v1/school/periods/${payload}`);
      commit("SET_DELETE_PERIODS", payload);
      commit("periodsSuccess", true);
      return resp;
    } catch (err) {
      commit("periodsErr", err);
    }
  },
};

const getters = {
  getPeriods: (state) => state.periodsState,
  periodsLoading: (state) => state.loading,
};

const mutations = {
  SET_PERIODS(state, payload) {
    state.periodsState = payload;
  },
  SET_NEW_PERIODS(state, payload) {
    state.periodsState.unshift(payload);
  },
  SET_DELETE_PERIODS(state, payload) {
    state.periodsState = state.periodsState.filter(
      (item) => item.id !== payload
    );
  },
  periodsBegin(state) {
    state.loading = true;
  },
  periodsSuccess(state) {
    state.loading = false;
  },
  periodsErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
