<template>
  <router-view />
</template>
<script>
import { core } from "./config/pluginInit";

export default {
  name: "App",
  components: {},
  beforeCreate() {
    core.mainIndex();
    if (JSON.parse(localStorage.getItem("dark"))) {
      document.querySelector("body").classList.add("dark");
    } else {
      document.querySelector("body").classList.remove("dark");
    }
  },
};
</script>
<style lang="scss">
@import "assets/scss/style.scss";

.vue-tel-input {
  border-radius: 7px !important;
  border: 1px solid rgb(211, 211, 211);
  padding: 4px;
}
.vs__dropdown-toggle {
  border-radius: 7px !important;
  padding: 8px 8px 8px 4px !important;
  border: 1px solid #adadad9c !important;
}
.vs__search {
  color: #6c757d !important;
}

.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__clear,
.vs--disabled .vs__search,
.vs--disabled .vs__selected,
.vs--disabled .vs__open-indicator {
  background-color: none !important;
}
</style>
