import { ServiceApi } from "@/services/auth";

const state = {
  colleges: [],
  college: null,
  collegeOnboarding: [],
  loading: false,
  error: null,
};

const actions = {
  async fetchColleges({ commit }, payload) {
    try {
      commit("collegesBegin");
      let response = await ServiceApi.get("/api/v1/colleges", payload);
      commit("SET_COLLEGES", response.data);
      commit("collegesSuccess", true);
      return response;
    } catch (err) {
      commit("collegesErr", err);
    }
  },
  async fetchCollege({ commit }, payload) {
    try {
      commit("SET_COLLEGE", null);
      commit("collegesBegin");
      let response = await ServiceApi.get(`/api/v1/colleges/${payload}`);
      commit("SET_COLLEGE", response.data);
      commit("collegesSuccess", true);
      return response;
    } catch (err) {
      commit("collegesErr", err);
    }
  },
  async storeColleges({ commit, dispatch }, payload) {
    let formData = new FormData();
    Object.entries(payload).forEach(([key, value]) =>
      formData.append(key, value)
    );
    try {
      commit("collegesBegin");
      if (!payload.id) {
        const resp = await ServiceApi.post("/api/v1/colleges", formData);
        commit("SET_NEW_COLLEGES", resp.data);
        commit("collegesSuccess", true);
        return resp;
      } else if (payload.id) {
        const resp = await ServiceApi.put(
          `/api/v1/colleges/${payload.id}`,
          payload
        );
        await dispatch("fetchColleges");
        commit("collegesSuccess", true);
        return resp;
      }
    } catch (err) {
      commit("collegesErr", err);
    }
  },
  async deleteCollege({ commit }, payload) {
    try {
      commit("collegesBegin");
      const resp = await ServiceApi.delete(`/api/v1/colleges/${payload}`);
      commit("SET_DELETE_COLLEGES", payload);
      commit("collegesSuccess", true);
      return resp;
    } catch (err) {
      commit("collegesErr", err);
    }
  },
  async updateSocialNetworksCollege({ commit }, payload) {
    try {
      commit("collegesBegin");
      const resp = await ServiceApi.put(
        `/api/v1/colleges/${payload.college_id}/update/social/networks`,
        payload
      );
      commit("collegesSuccess", true);
      return resp;
    } catch (err) {
      commit("collegesErr", err);
    }
  },
  async addImageCollege({ commit, getters, dispatch }, payload) {
    let dataForm = new FormData();
    dataForm.append(payload.name_image, payload.image);
    const urlCollegeLogos = {
      image: "image/add",
      image_menu: "image/menu/add",
      image_splash: "image/splash/add",
    };
    const collegeId = payload.id ? payload.id : getters.collageAuthUser.id;
    const url = `/api/v1/colleges/${collegeId}/${
      urlCollegeLogos[payload.name_image]
    }`;
    try {
      commit("collegesBegin");
      const resp = await ServiceApi.post(url, dataForm);
      await dispatch("fetchColleges");
      commit("collegesSuccess", true);
      return resp;
    } catch (err) {
      commit("collegesErr", err);
    }
  },
  async fetchCollegeOnboarding({ commit }, payload) {
    try {
      commit("collegesBegin");
      let response = await ServiceApi.get(
        `/api/v1/colleges/${payload}/onboarding`
      );
      commit("SET_COLLEGE_ONBOARDING", response.data.onboarding);
      commit("collegesSuccess", true);
      return response;
    } catch (err) {
      commit("collegesErr", err);
    }
  },
  async addImageCollegeOnboarding({ commit, dispatch }, payload) {
    let dataForm = new FormData();
    dataForm.append("title", payload.title);
    dataForm.append("content", payload.content);
    dataForm.append("image_onboarding", payload.image);
    try {
      commit("collegesBegin");
      const resp = await ServiceApi.post(
        `/api/v1/colleges/${payload.college_id}/onboarding/add`,
        dataForm
      );
      await dispatch("fetchCollegeOnboarding", payload.college_id);
      commit("collegesSuccess", true);
      return resp;
    } catch (err) {
      commit("collegesErr", err);
    }
  },
  async deleteCollegeOnboarding({ commit }, payload) {
    try {
      commit("collegesBegin");
      const resp = await ServiceApi.delete(
        `/api/v1/colleges/${payload}/image/delete`
      );
      commit("collegesSuccess", true);
      return resp;
    } catch (err) {
      commit("collegesErr", err);
    }
  },
};

const getters = {
  colleges: (state) => state.colleges,
  college: (state) => state.college,
  collegeOnboarding: (state) => state.collegeOnboarding,
  collegeLoading: (state) => state.loading,
};

const mutations = {
  SET_COLLEGES(state, payload) {
    state.colleges = payload;
  },
  SET_COLLEGE(state, payload) {
    state.college = payload;
  },
  SET_COLLEGE_ONBOARDING(state, payload) {
    state.collegeOnboarding = payload;
  },
  SET_NEW_COLLEGES(state, payload) {
    state.colleges.data.unshift(payload);
  },
  SET_DELETE_COLLEGES(state, payload) {
    state.colleges.data = state.colleges.data.filter(
      (item) => item.id !== payload
    );
  },
  collegesBegin(state) {
    state.loading = true;
  },
  collegesSuccess(state) {
    state.loading = false;
  },
  collegesErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
