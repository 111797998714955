import { ServiceApi } from "@/services/auth";

const state = {
  chats: [],
  chatMessages: [],
  chatTemplates: [],
  loading: false,
  loadingUpload: false,
  error: null,
};

const actions = {
  async getChatsUserAuth({ commit, getters }) {
    try {
      commit("messengerBegin");
      commit("SET_CHAT_MESSAGES", []);
      let response = await ServiceApi.get(
        `/api/v1/chats/user/${getters.collageAuthUser?.id}/all`
      );
      commit("SET_CHATS", response.data);
      commit("messengerSuccess", true);
      return response;
    } catch (err) {
      commit("messengerErr", err);
    }
  },
  async getChatTemplatesAuthCollege({ commit, getters }) {
    try {
      commit("messengerBegin");
      let response = await ServiceApi.get(
        `/api/v1/chat-templates/${getters.collageAuthUser?.id}/all`
      );
      commit("SET_CHAT_TEMPLATES", response.data);
      commit("messengerSuccess", true);
      return response;
    } catch (err) {
      commit("messengerErr", err);
    }
  },
  async getChatMessages({ commit }, payload) {
    try {
      commit("messengerBegin");
      commit("SET_CHAT_MESSAGES", []);
      let response = await ServiceApi.get(`/api/v1/chats/${payload}/messages`);
      commit("SET_CHAT_MESSAGES", response.data);
      commit("messengerSuccess", true);
      return response;
    } catch (err) {
      commit("messengerErr", err);
    }
  },
  async sendNewMessage({ commit }, payload) {
    try {
      const resp = await ServiceApi.post(
        `/api/v1/chats/${payload.chat_id}/store/message`,
        payload
      );
      return resp;
    } catch (err) {
      commit("messengerErr", err);
    }
  },
  async storeNewChat({ commit, getters }, payload) {
    try {
      const resp = await ServiceApi.post(
        `/api/v1/chats/${getters.collageAuthUser?.id}/create`,
        payload
      );
      return resp;
    } catch (err) {
      commit("messengerErr", err);
    }
  },
  async createOrUpdateChatTemplate({ commit, getters }, payload) {
    try {
      commit("messengerBegin");
      if (!payload.id) {
        const resp = await ServiceApi.post(
          `/api/v1/chat-templates/${getters.collageAuthUser?.id}/create`,
          payload
        );
        commit("messengerSuccess", true);
        return resp;
      } else if (payload.id) {
        const resp = await ServiceApi.put(
          `/api/v1/chat-templates/${payload.id}/update`,
          payload
        );
        commit("messengerSuccess", true);
        return resp;
      }
    } catch (err) {
      commit("messengerErr", err);
    }
  },
  async addUserFromChat({ commit }, payload) {
    try {
      const resp = await ServiceApi.post(
        `/api/v1/chats/${payload.chat_id}/${payload.user_to_add_id}/add/user`,
        payload
      );
      return resp;
    } catch (err) {
      commit("messengerErr", err);
      return err;
    }
  },
  async uploadAvatarChat({ commit }, payload) {
    try {
      commit("messengerBeginUpload");
      let formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("avatar", payload.avatar);
      const response = await ServiceApi.post(
        `/api/v1/chats/${payload.chat_id}/update/avatar`,
        formData
      );
      commit("CHANGE_AVATAR_CHAT", payload);
      commit("messengerSuccessUpload", true);
      return response;
    } catch (err) {
      commit("messengerErr", err);
    }
  },
  async uploadAvatarChatTemplate({ commit }, payload) {
    try {
      commit("messengerBeginUpload");
      let formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("avatar", payload.avatar);
      const response = await ServiceApi.post(
        `/api/v1/chat-templates/${payload.chat_template_id}/update/avatar`,
        formData
      );
      commit("CHANGE_AVATAR_CHAT_TEMPLATE", payload);
      commit("messengerSuccessUpload", true);
      return response;
    } catch (err) {
      commit("messengerErr", err);
    }
  },
  async deleteUserFromChat({ commit }, payload) {
    try {
      const resp = await ServiceApi.delete(
        `/api/v1/chats/${payload.chat_id}/${payload.user_to_delete_id}/delete/user`
      );
      return resp;
    } catch (err) {
      commit("messengerErr", err);
      return err;
    }
  },
  async deleteChatMessage({ commit }, payload) {
    try {
      commit("messengerBegin");
      const resp = await ServiceApi.delete(`/api/v1/chats/${payload}/message`);
      commit("messengerSuccess", true);
      return resp;
    } catch (err) {
      commit("messengerErr", err);
    }
  },
};

const getters = {
  chats: (state) => state.chats,
  chatTemplates: (state) => state.chatTemplates,
  chatMessages: (state) => state.chatMessages,
  messengerLoading: (state) => state.loading,
  messengerLoadingUpload: (state) => state.loadingUpload,
};

const mutations = {
  SET_CHATS(state, payload) {
    state.chats = payload;
  },
  SET_CHAT_MESSAGES(state, payload) {
    state.chatMessages = payload;
  },
  SET_CHAT_TEMPLATES(state, payload) {
    state.chatTemplates = payload;
  },
  CHANGE_AVATAR_CHAT(state, payload) {
    state.chats.forEach((chat) => {
      if (chat.id === payload.chat_id) {
        chat.avatar = payload.preview;
      }
    });
  },
  CHANGE_AVATAR_CHAT_TEMPLATE(state, payload) {
    state.chatTemplates.forEach((chatTemplate) => {
      if (chatTemplate.id === payload.chat_template_id) {
        chatTemplate.avatar = payload.preview;
      }
    });
  },
  messengerBegin(state) {
    state.loading = true;
  },
  messengerBeginUpload(state) {
    state.loadingUpload = true;
  },
  messengerSuccess(state) {
    state.loading = false;
  },
  messengerSuccessUpload(state) {
    state.loadingUpload = false;
  },
  messengerErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
