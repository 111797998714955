import { ServiceApi } from "../../services/auth";

const state = {
  commune: [],
  socialNetworks: [],
  roleCollege: [],
  loading: false,
  error: null,
};

const actions = {
  async fetchCommune({ commit }, payload) {
    try {
      commit("toolsBegin");
      const response = await ServiceApi.get("/api/v1/tools/communes", payload);
      commit("SET_COMMUNE", response);
      commit("toolsSuccess", true);
      return response;
    } catch (err) {
      commit("communeErr", err);
    }
  },
  async fetchSocialNetworks({ commit }, payload) {
    try {
      commit("toolsBegin");
      const response = await ServiceApi.get(
        "/api/v1/tools/social-networks",
        payload
      );
      commit("SET_SOCIAL_NETWORKS", response);
      commit("toolsSuccess", true);
      return response;
    } catch (err) {
      commit("toolsErr", err);
    }
  },
  async fetchRolesCollage({ commit }) {
    try {
      commit("toolsBegin");
      const response = await ServiceApi.get("/api/v1/roles");
      commit("SET_ROLE_COLLEGE", response);
      commit("toolsSuccess", true);
      return response;
    } catch (err) {
      commit("toolsErr", err);
    }
  },
};

const getters = {
  communes: (state) => state.commune,
  socialNetworks: (state) => state.socialNetworks,
  roleCollege: (state) => state.roleCollege,
  communeLoading: (state) => state.loading,
};

const mutations = {
  SET_COMMUNE(state, payload) {
    state.commune = payload.data;
  },
  SET_SOCIAL_NETWORKS(state, payload) {
    state.socialNetworks = payload.data;
  },
  SET_ROLE_COLLEGE(state, payload) {
    state.roleCollege = payload.data;
  },
  toolsBegin(state) {
    state.loading = true;
  },
  toolsSuccess(state) {
    state.loading = false;
  },
  toolsErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
