import { ServiceApi } from "@/services/auth";

const state = {
  companies: [],
  company: null,
  loading: false,
  error: null,
};

const actions = {
  async fetchCompanies({ commit }, payload) {
    try {
      commit("companiesBegin");
      let response = await ServiceApi.get("/api/v1/companies", payload);
      commit("SET_COMPANIES", response.data);
      commit("companiesSuccess", true);
      return response;
    } catch (err) {
      commit("companiesErr", err);
    }
  },
  async fetchCompany({ commit }, payload) {
    try {
      commit("companiesBegin");
      let response = await ServiceApi.get(`/api/v1/companies/${payload}`);
      commit("SET_COMPANY", response.data);
      commit("companiesSuccess", true);
      return response;
    } catch (err) {
      commit("companiesErr", err);
    }
  },
  async createOrUpdateCompany({ commit, dispatch }, payload) {

    let formData = new FormData;
    formData.append("name", payload.name)
    formData.append("business_name", payload.business_name)
    formData.append("rut", payload.rut)
    if(payload.avatar) formData.append("avatar", payload.avatar);
    try {
      commit("companiesBegin");
      if (!payload.id) {
        const resp = await ServiceApi.post(`/api/v1/companies`, formData);
        commit("companiesSuccess", true);
        commit("SET_NEW_COMPANY", resp);
        return resp;
      } else if (payload.id) {
        formData.append("_method", "PUT");
        const resp = await ServiceApi.post(
          `/api/v1/companies/${payload.id}`,
          formData
        );
        commit("companiesSuccess", true);
        dispatch("fetchCompanies");
        return resp;
      }
    } catch (err) {
      commit("companiesErr", err);
    }
  },
  async deleteCompany({ commit }, payload) {
    try {
      commit("companiesBegin");
      const resp = await ServiceApi.delete(`/api/v1/companies/${payload}`);
      commit("SPLICE_COMPANY_DELETED", payload);
      commit("companiesSuccess", true);
      return resp;
    } catch (err) {
      commit("companiesErr", err);
    }
  },
  async addUserToCompany({ commit, dispatch }, payload) {
    try {
      commit("companiesBegin");
      const resp = await ServiceApi.post(
        `/api/v1/companies/${payload.company_id}/add/user`,
        payload
      );
      dispatch("fetchCompany", payload.company_id);
      commit("companiesSuccess", true);
      return resp;
    } catch (err) {
      commit("companiesErr", err);
    }
  },
  async deleteCompanyUser({ commit, dispatch }, payload) {
    try {
      commit("companiesBegin");
      const resp = await ServiceApi.post(
        `/api/v1/companies/${payload.company_id}/delete/user`,
        payload
      );
      dispatch("fetchCompany", payload.company_id);
      commit("companiesSuccess", true);
      return resp;
    } catch (err) {
      commit("companiesErr", err);
    }
  },
};

const getters = {
  companies: (state) => state.companies,
  company: (state) => state.company,
  companiesLoading: (state) => state.loading,
};

const mutations = {
  SET_COMPANIES(state, payload) {
    state.companies = payload;
  },
  SET_COMPANY(state, payload) {
    state.company = payload;
  },
  SET_NEW_COMPANY(state, payload) {
    state.companies.data.unshift(payload);
  },
  SPLICE_COMPANY_DELETED(state, payload) {
    state.companies.data = state.companies.data.filter(
      (item) => item.id !== payload
    );
  },
  companiesBegin(state) {
    state.loading = true;
  },
  companiesSuccess(state) {
    state.loading = false;
  },
  companiesErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
