<template>
  <ul class="iq-timeline" style="padding-left: 0.5px">
    <li
      v-for="(item, index) in items"
      :key="index"
      @click="emitCurrentItem(item)"
    >
      <div
        class="timeline-dots"
        :style="`border: 4px solid ${item.color}`"
      ></div>
      <h6 class="float-left mb-1" v-html="item.title"></h6>
      <i
        class="ri-alert-fill mx-2 text-warning"
        v-b-tooltip.hover
        :title="$t('notifications.emergency')"
        v-if="item.emergency"
      ></i>
      <small class="float-right mt-1" v-html="item.right"></small>
      <div class="d-inline-block w-100">
        <br />
        <p v-html="item.description"></p>
        <b-button
          variant="outline-primary"
          @click="emitCurrentItem(item)"
          size="sm"
          class="float-right"
        >
          <span>
            {{ $t("app.show") }}
          </span>
          <i class="ri-eye-fill pr-0"></i>
        </b-button>
        <template
          v-if="item.child.items.length > 0 && item.child.type === 'img'"
        >
          <div class="iq-media-group">
            <a
              href="#"
              class="iq-media"
              v-for="(childItem, childIndex) in item.child.items"
              :key="childIndex"
            >
              <img
                :src="childItem"
                class="img-fluid avatar-40 rounded-circle"
                alt="img"
              />
            </a>
          </div>
        </template>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "TimeLine",
  props: {
    items: { type: Array, default: () => [] },
  },
  methods: {
    emitCurrentItem(item) {
      this.$emit("showCurrentItem", item);
    },
  },
};
</script>
<style>
.iq-timeline {
  width: 100% !important;
}
</style>
