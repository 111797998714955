import { ServiceApi } from "@/services/auth";

const state = {
  flyers: [],
  loading: false,
  error: null,
};

const actions = {
  async fetchFlyers({ commit }, payload) {
    try {
      commit("flyersBegin");
      let response = await ServiceApi.get(`/api/v1/flyers`, payload);
      commit("SET_FLYERS", response.data);
      commit("flyerSuccess", true);
      return response;
    } catch (err) {
      commit("flyerErr", err);
    }
  },
  async storeFlyer({ commit }, payload) {
    try {
      let dataForm = new FormData();
      dataForm.append("title", payload.title);
      dataForm.append("content", payload.content);
      dataForm.append("category_id", payload.category.id);
      dataForm.append("name_company", payload.name_company);
      dataForm.append("link", payload.link);
      if (payload.image) {
        dataForm.append("image", payload.image);
      }
      if (payload.logo_company) {
        dataForm.append("logo_company", payload.logo_company);
      }
      commit("flyersBegin");
      if (!payload.id) {
        const resp = await ServiceApi.post(`/api/v1/flyers`, dataForm);
        commit("flyerSuccess", true);
        return resp;
      } else if (payload.id) {
        dataForm.append("_method", "PUT");
        const resp = await ServiceApi.post(
          `/api/v1/flyers/${payload.id}`,
          dataForm
        );
        // dispatch("fetchFlyers");
        commit("flyerSuccess", true);
        return resp;
      }
    } catch (err) {
      console.log(err);
      commit("flyerErr", err);
    }
  },
  async deleteFlyer({ commit }, payload) {
    try {
      commit("flyersBegin");
      const resp = await ServiceApi.delete(`/api/v1/flyers/${payload}`);
      commit("flyerSuccess", true);
      return resp;
    } catch (err) {
      commit("flyerErr", err);
    }
  },
};

const getters = {
  getFlyers: (state) => state.flyers,
  flyerLoading: (state) => state.loading,
};

const mutations = {
  SET_FLYERS(state, payload) {
    state.flyers = payload;
  },
  flyersBegin(state) {
    state.loading = true;
  },
  flyerSuccess(state) {
    state.loading = false;
  },
  flyerErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
